import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import teamPhoto from "../../static/HSCI_teamPhoto_opt.png"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <h1>About Us</h1>

    {/*<div className={'flex-image-wrap'}>*/}
    {/*  <img className={'about-us-photo flex-image'} src={teamPhoto} alt={'Photo graph of the Hydronic Systems team'}/>*/}
    {/*  <div className={'flex-image-caption'}>*/}
    {/*    <h2>Meet Our Team</h2>*/}
    {/*    <p>L-R, Ralph Reimann, Michael Reimann, Fernanda Gravina, Tim Rickards, Megan Rickards, Joe Da Silva, Samaria Iacobelli, John Raymond, Jeff House.</p>*/}
    {/*  </div>*/}
    {/*</div>*/}

    <p>
      HSCI, incorporated in January 1992, specializes in the representation and
      distribution of high performance, long lasting products for the hot water
      heating industry. Conveniently located in Toronto adjacent to Pearson
      International Airport, the company provides sales, distribution,
      marketing, training and field support for premium brands of heating
      boilers, controls and accessories for all types of residential and
      commercial applications.
    </p>

    <p>
      With a keen eye for innovation and long term commitment to furthering the
      hydronic industry, we have continually introduced leading edge products to
      a growing market. Performance, superior quality and long life have
      provided our customers with the comfort only a hot water heating system
      can provide. Energy efficiency and low maintenance have combined to
      deliver economy of operation and low life cycle costs to our systems.
    </p>

    <p>
      Our brands hold an enviable reputation in today’s hydronic market. With
      more than forty years of industry experience, our company proudly provides
      unparalleled product and field support to contractors, wholesale
      suppliers, design engineers, architects and all levels of government.
    </p>
    <br />
    <br />
    <h2>The Benefits of Hydronic Heating</h2>
    <p>
      Hydronic heating is the most comfortable, cost efficient method of heating
      used in homes and buildings today. Hydronic heating is versatile, quiet
      and economical. It can be incorporated into almost any type of home or
      building, utilizing any modern fuel source.
    </p>
    <p>
      Hydronic radiant systems circulate water through tubing in the floors,
      through convectors or radiators along the walls and through fan coils to
      warm the surfaces of a home. When compared to air, there are many
      advantages to heating your home using water that will create an optimal
      experience of comfort in your home.
    </p>
    <ul>
      <li>Surfaces within the space are heated rather than the air itself</li>
      <li>
        Heat is evenly distributed within each space to provide maximum comfort,
        thus eliminating hot or cold spots
      </li>
      <li>
        There is less circulation of dust and particles, which can cause
        allergies
      </li>
      <li>
        Hydronic heating systems eliminate fan noises and stop/start sounds
        typical of a forced air furnace and ductwork system, which promotes a
        clean and quiet living space
      </li>
      <li>
        Your home or building can be zoned to control individual room
        temperatures according to how you use each space
      </li>
      <li>
        You can control the temperature of your floors to set your optimal level
        of comfort
      </li>
      <li>
        You can monitor your hydronic system through WIFI, home automation or
        building automation controls to achieve maximum energy efficiency
      </li>
      <li>
        You can incorporate additional modes of comfort, such as domestic hot
        water, snow melting and pool heating, in order to achieve an efficient
        system from a single heat source
      </li>
    </ul>
    <br />
    <br />
  </Layout>
)

export default AboutUsPage
